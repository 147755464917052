/** @jsxImportSource @emotion/react */
import { useAccount, useMsal } from '@azure/msal-react';
import { jsx } from '@emotion/react';
import { uniq } from 'lodash';
import React, { useContext } from 'react';

import { Header, Sidebar, UserGroupLabel } from 'components';
import { localStorageRemoveAllItem, mq } from 'helpers';
import { USER_ROLE } from 'helpers/constants';
import ProfileContext from 'store/ProfileContext';

interface CommonContainerProps {
  children?: React.ReactNode;
}

const CommonContainer: React.FC<CommonContainerProps> = ({
  children,
}: CommonContainerProps) => {
  const { isShowSidebar, setIsShowSidebar, galacxyId, userRoles, userGroup } =
    useContext(ProfileContext);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const signOut = async () => {
    const logoutRequest = {
      account,
      postLogoutRedirectUri: '/',
    };

    localStorageRemoveAllItem();

    await instance.logoutRedirect(logoutRequest);
  };

  const userRoleText = uniq(
    userRoles
      .filter((role) => role !== USER_ROLE.VIEWER.name)
      .map(
        (role) =>
          Object.values(USER_ROLE).find((x) => x.name === role)?.display,
      ),
  ).join(', ');

  return (
    <div
      css={{
        marginTop: 0,
      }}
    >
      <Header
        title="Operation Knowledge Repository"
        toggleSideNav={() => {
          setIsShowSidebar(!isShowSidebar);
        }}
        signOut={signOut}
        galacxyId={galacxyId}
        userRoleText={userRoleText}
        userGroupLabelComponent={<UserGroupLabel userGroup={userGroup} />}
      />
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          minHeight: '100vh',
          [mq[1]]: { display: 'block' },
        }}
      >
        <div css={{ display: isShowSidebar ? 'flex' : 'none' }}>
          <Sidebar />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 1,
            [mq[1]]: {
              display: isShowSidebar ? 'none' : 'block',
            },
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default CommonContainer;
